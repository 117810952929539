export default {
  data() {
    return {
      service: {
        service_name: '',
        key: '',
        conversions: [],
        plugins: [],
        pages: []
      },
      temporaryServiceId: 0,
    }
  },
  created() {
    this.login_required = true;
    if(this.$store.getters.temporaryService) {
      let service = this.$store.getters.temporaryService;
      this.service.service_name = service.name;
      this.service.key = service.key;
      this.service.conversions = service.service.conversions;
      this.service.plugins = service.service.plugins;
      this.service.pages = service.service.pages;
      if(this.$route.query.step) {
        this.step = parseInt(this.$route.query.step);
      }
      this.temporaryServiceId  = service.id;
      this.$store.commit('setTemporaryService', undefined);
    }
  },
  methods: {
    //임시저장하기
    clickTemp() {
      if(this.service.service_name === '') {
        this.toast('서비스명을 먼저 입력해주세요');
        return;
      }
      this.saveTemp(true);
    },
    async saveTemp(msg) {
      this.service.step = this.step;
      let result = res => {
        this.temporaryServiceId = res.data.id;
        if(msg){
          this.toast('임시저장되었습니다');
        }
      };
      if(this.temporaryServiceId === 0) {
        let res = await this.request.user.post('ui/super_builder/temporary', { service: this.service });
        result(res);
      } else {
        let res = await this.request.user.patch(`ui/super_builder/temporary/${this.temporaryServiceId}`, { service: this.service });
        result(res);
      }
    },
    saveTempRoute() {
      if(this.temporaryServiceId!==0) {
        let url = `ui/super_builder/temporary/${this.temporaryServiceId}`;
        this.request.user.patch(url, { service: this.service }).then(()=>{
          this.request.user.post(`${url}/route`);
        });
      }
    },
  }
}
