<template>
  <div style="height:100%">
    <block-builder :step="5" :service="service"></block-builder>
  </div>
</template>

<script>
  import BlockBuilder from "./Components/BlockBuilder";
  export default {
    name: "Step5",
    components: {BlockBuilder},
    props: {
      service: {
        type: Object
      }
    },
    created() {
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style lang="stylus" scoped>

</style>
